@import '~bootstrap/scss/bootstrap';

// ResizeObserver loop completed with undelivered notifications error suppressing
iframe#webpack-dev-server-client-overlay{display:none!important}

.spinner-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set the height to 100% of the viewport height */
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000; // large z-index to make sure it's on top of everything
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: none;
  justify-content: center;
  align-items: center;
}

.app-title {
  text-align: center;
}

.nrw-pretty-box-layout {
  max-width: 400px;
  padding-bottom: 20px;
  margin: auto;
}

.wide-pretty-box-layout {
  max-width: 600px;
  padding-bottom: 20px;
  margin: auto;
}

.sup-wide-pretty-box-layout {
  max-width: 1500px;
  padding: 20px;
  margin: auto;
}
  
.pretty-box {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.pretty-box label {
  margin-top: 10px;
}

.pretty-box-heading {
  text-align: center;
  margin-bottom: 20px;
}

.pretty-box-button {
  width: 100%;
  margin-top: 10px;
}

.pretty-box.required .form-label:after {
  content:"*";
  color:red;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 50px;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 50px;
}

.hyperlink {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

/* Some overriding for small screen */
.ag-paging-panel {
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  min-height: 100px;
}

.ag-header-cell-label {
  justify-content: center;
}

.magic-grid-norm-cell {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-wrap: wrap;
  text-align: center;
}

.magic-grid-checkbox-cell {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  text-wrap: wrap;
  text-align: center;
}